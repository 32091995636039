
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    color: black;
    /* font-weight: bold; */
}
html,body{
    margin: 0;
    height: 100%
}
body{
    background: linear-gradient(193deg, rgba(223,157,32,1) 0%, rgba(158,67,25,1) 100%, rgba(0,212,255,1) 100%); 

}
#c {
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
}

.mobileButtons{
    display: none;
    width: 200px;
    height: 60px;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.mobileButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 10px;
    color:rgba(255,255,255,0.6);
    border: 2px solid rgba(255,255,255,0.6);
    border-radius: 3px;
}

.keybindingsHint{
    display: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.keybindingsWindowContainer{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
}
.keybindingsWindow{
    position: relative;
    background: rgb(223,157,32);
    background: linear-gradient(193deg, rgba(223,157,32,1) 0%, rgba(158,67,25,1) 100%, rgba(0,212,255,1) 100%); 
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 350px;

}
.keybindings{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    width: 600px;
    height: 350px;
    
}
.close{
    position: absolute;
    right: 10px;
    top: 5px;
}
.keybinding{
    display: flex;
    align-items: center;
    width: 250px;
    height: 50px;
    margin: 5px;
    display: flex;
}
.key{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid black;
    border-radius: 3px;
}
.padding{
    width: 20px;
}
.arrow{
    border: solid black;
    border-width: 0 3px 3px 0;
    /* display: inline-block; */
    padding: 3px
}
.key{
    color: black;
}
.fas{
    color : black;
}
.up .arrow{
    transform: rotate(-135deg);

}
.arrow .left{
    transform: rotate(135deg)
}
.arrow .down{
    transform: rotate(45deg)
}
.arrow .right{
    transform: rotate(-45deg)
}

.description{
    color: black;
}
.title{
    width: 100%;
    text-align: center;
    font-size: 30px;
    
}




